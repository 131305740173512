import React from 'react'
import Layout from "../components/layout/index"

const About = () => {
  return (
    <Layout>
    </Layout>
  )
}

export default About